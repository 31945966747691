import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import AccountsLayout from "./components/AccountsLayout";
import {
  public_routes,
  sa_routes,
  account_routes,
  test_routes,
} from "./routes";
import RequireAuth from "./auth/RequireAuth";
import { ROLES } from "./constants";
// import { useSelector } from "react-redux";
import { LogOut } from "./auth/LogOut";
import packageInfo from "../package.json";

function App() {
  const location = useLocation();
  const version = packageInfo.version;
  // const { appVersion } = useSelector((state) => state.user);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (
        !localStorage.getItem("version") &&
        localStorage.getItem("isAuthenticated") === "true"
      ) {
        localStorage.setItem("version", version);
        document.location.reload(true);
        // console.log("No version in local storage");
        // LogOut();
      }

      if (
        localStorage.getItem("version") !== version &&
        localStorage.getItem("isAuthenticated") === "true"
      ) {
        localStorage.setItem("version", version);
        document.location.reload(true);
        // window.location.reload();
        // console.log("Version in local storage is different", version);
        // LogOut();
      }
      if (
        localStorage.getItem("reduxVersion") !==
          process.env.REACT_APP_REDUX_STORE_VERSION &&
        localStorage.getItem("isAuthenticated") === "true"
      ) {
        LogOut();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [location, version]);

  return (
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.User,
                ROLES.Gestor,
                ROLES.Admin,
                ROLES.SuperUser,
                ROLES.SubsidiaryAdmin,
              ]}
            />
          }
        >
          {public_routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
        </Route>
        {/*
         *
         * ?Routes used for testing purposes
         *
         */}
        <Route
          element={
            <RequireAuth allowedRoles={[]} allowedPermissions={["tester"]} />
          }
        >
          {test_routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[ROLES.SuperUser]}
              allowedPermissions={["crud: normal users"]}
            />
          }
        >
          {sa_routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
        </Route>
      </Route>
      <Route path="/accounts/*" element={<AccountsLayout />}>
        {account_routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          );
        })}
      </Route>
      <Route
        path="login"
        exact
        name="Login"
        element={
          localStorage.getItem("isAuthenticated") === "true" ? (
            <Navigate to="/" replace />
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/"
        state={{ from: location }}
        element={<Navigate to="buscar_factura" replace />}
      />
      <Route
        path="rutas/detalle_ruta"
        element={<Navigate to="/rutas" replace />}
      />
    </Routes>
  );
}

export default App;
