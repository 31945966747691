import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

var today = new Date();

const initialState = {
  transactions_history_form: {
    subsidiary_id: "",
    department_id: "",
    user_action: "",
    fecha: new Date(),
  },
  transactions_history_step: 0,
};

export const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearStateTransactionsHistoryForm: (state) => {
      state.transactions_history_form = initialState.transactions_history_form;
      return state;
    },
    updateTransactionsHistoryForm: (state, { payload }) => {
      state.transactions_history_form = payload;
      return state;
    },
    changeStepTransactions: (state, { payload }) => {
      state.transactions_history_step = payload.step;
      return state;
    },
  },
});

export const {
  clearStateTransactionsHistoryForm,
  updateTransactionsHistoryForm,
  changeStepTransactions,
  reset,
} = formSlice.actions;

export const selectForm = (state) => state.form;

export default formSlice.reducer;
