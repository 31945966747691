import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";


function FormInputEmail({ name, control, label, req, disabled}) {
    return (
        <Controller
        name={name}
        control={control}
        rules={{
          required: req ? 'Este campo no puede quedar vacío': false,
          pattern: {
              value:  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Ingrese una dirección de correo electrónico válida."
          }
        }}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
          formState
        }) => (
          <TextField
            autoFocus
            helperText={error ? error.message : null}
            disabled={disabled}
            size="small"
            error={!!error}
            onChange={onChange}
            autoComplete="off"
            value={value}
            fullWidth
            label={label}
            variant="outlined"
          />
        )}
      />
    )
}

FormInputEmail.propTypes = {
    name: PropTypes.string,
    control: PropTypes.any,
    label: PropTypes.string,
}

export default FormInputEmail
