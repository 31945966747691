import React, { useState, useEffect } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
// import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
// import MailIcon from "@material-ui/icons/Mail";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ConfirmationDialog from "./global/Dialogs/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  buscarFactura,
  selectInvoice,
  clearStateBusqueda,
} from "../features/invoiceSlice";
import { selectUser } from "../features/userSlice";
import { Tooltip, CircularProgress } from "@material-ui/core";

import { LogOut } from "../auth/LogOut";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hide: {
    display: "none",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default function NavbarV2({ showNav, setShowNav, path }) {
  const {
    isSuccessBusqueda,
    facturas_encontradas,
    isFetchingBusqueda,
    isErrorBusqueda,
  } = useSelector(selectInvoice);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openHelp, setOpenHelp] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [folio, setFolio] = useState("");
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { name } = useSelector(selectUser);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleProfileMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setShowNav(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseObservations = () => {
    setOpenHelp(false);
  };

  const handleCloseLogOut = () => {
    setOpenLogOut(false);
  };

  const logOut = () => {
    LogOut();
  };

  const searchInvoice = (event) => {
    event.preventDefault();
    if (folio !== "") {
      setSearch(true);
      let params = {
        folio: folio,
      };
      dispatch(buscarFactura({ params: params }));
    }
  };

  useEffect(() => {
    if (isSuccessBusqueda && search) {
      const length = facturas_encontradas.data.length;

      if (length > 1) {
        const url = `/detalle_factura/${
          facturas_encontradas.data[length - 1].id
        }`;
        window.open(url);
        dispatch(clearStateBusqueda());
        setFolio("");
      } else if (length === 1) {
        const url = `/detalle_factura/${facturas_encontradas.data[0].id}`;
        window.open(url);
        // dispatch(selectInvoice(facturas_encontradas.data[0]));
        dispatch(clearStateBusqueda());
        setFolio("");
      }
    }
    if (isErrorBusqueda && search) {
      setFolio("");
      setOpenDialog(true);
      dispatch(clearStateBusqueda());
    }
    setSearch(false);
  }, [isSuccessBusqueda, isErrorBusqueda]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Cambiar contraseña</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>Cerrar sesión</MenuItem> */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={() => setOpenHelp(true)}
          color="inherit"
        >
          <HelpOutlineIcon />
        </IconButton>
        <p>Ayuda</p>
      </MenuItem>

      <MenuItem>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={() => setOpenLogOut(true)}
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Cerrar sesión</p>
      </MenuItem>

      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor:
            process.env.REACT_APP_ENV === "PROD"
              ? "#094b9b"
              : process.env.REACT_APP_ENV === "QAS"
              ? "#d8051f"
              : "#e91e63",
        }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: showNav,
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, {
              [classes.hide]: showNav,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {path}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              {isFetchingBusqueda && search ? (
                <CircularProgress />
              ) : (
                <SearchIcon />
              )}
            </div>
            <form onSubmit={searchInvoice}>
              <InputBase
                placeholder="Buscar factura por folio…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={folio}
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => setFolio(event.target.value)}
                //onSubmit={searchInvoice}
              />
            </form>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              u
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              //onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
              <Typography variant="h6">{name}</Typography>
            </IconButton>
            <Tooltip title="Ayuda">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => setOpenHelp(true)}
                color="inherit"
              >
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cerrar sesión">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => setOpenLogOut(true)}
                color="inherit"
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <ConfirmationDialog
        open={openLogOut}
        title={"Cerrar sesión"}
        handleAccept={logOut}
        handleClose={handleCloseLogOut}
      >
        <Typography>Se cerrará la sesión. ¿Desea continuar?</Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={openDialog}
        title={"No se encontró factura"}
        handleAccept={handleCloseDialog}
        handleClose={handleCloseDialog}
      >
        <h2>No se encontró ningun documento</h2>
        <Typography>
          El folio no coincide con ningún documento en la base de datos.
        </Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={openHelp}
        title={"Ayuda ?"}
        handleAccept={handleCloseObservations}
        handleClose={handleCloseObservations}
      >
        <h2>Manual de uso</h2>
        <Typography>
          Si desea consultar el manual de uso del Tracking de facturas, lo puede
          consultar en el siguiente enlace:
        </Typography>
        <a
          href={
            process.env.REACT_APP_COMPANY === "CILLASA"
              ? "https://docs.google.com/presentation/d/1hDuA4guC_gxV5fmwrLA5ZNaydDeo76R8/edit?usp=sharing&ouid=111643404657244547349&rtpof=true&sd=true"
              : "https://docs.google.com/presentation/d/1po4oXOYwbWqfybdLN0ev31gB1cC00FwA/edit?usp=sharing&ouid=111643404657244547349&rtpof=true&sd=true"
          }
          target="_blank"
        >
          Manual de uso
        </a>
        <h2>Reportar un error</h2>
        <Typography>
          Para reportar un error/mal funcionamiento/funcionamiento
          inesperado/bug se deben seguir los siguientes pasos:
        </Typography>
        <ol>
          <li>
            Redactar un texto en el cual se especifique el error siguiendo la
            siguiente estructura
          </li>
          <ol>
            <li>
              <b>¿Que se intentó hacer?</b> Ejemplo: Estaba en el módulo de
              transacción express y quise pasarle 3 facturas a otro usuario
            </li>
            <li>
              <b>¿En específico que datos se quieren modificar?</b> Ejemplo:
              Intenté pasar las facturas con los folios (455512323, 45688795,
              456622467)
            </li>
            <li>
              <b>Intentar especificar lo más posible</b> Ejemplo: El sistema me
              permite escanear las primeras dos facturas pero la tercera no la
              encuentra
            </li>
            <li>
              <b>Tomar screenshot</b> De preferencia, anexar un screenshot del
              acontecimiento
            </li>
          </ol>
          <li>
            Redactar un correo con toda la información del punto anterior y
            mandar a los siguientes correos
          </li>
          <ul>
            <li>
              {" "}
              <b>
                {process.env.REACT_APP_COMPANY === "CILLASA"
                  ? "jvega@circulollantero.com.mx"
                  : "soporte@lubtrac.com.mx"}
              </b>{" "}
            </li>
          </ul>
          <li>
            Listo, una vez realizado el procedimiento correctamente nos
            pondremos en contacto lo más rápido posible para solucionar el
            error.
          </li>
        </ol>
      </ConfirmationDialog>
    </div>
  );
}
