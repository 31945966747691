import { useLocation, Navigate, Outlet } from "react-router-dom";
import { selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { checkPermission } from "../helpers/CheckPermission";
import { checkRole } from "../helpers/CheckRole";

const RequireAuth = ({ allowedRoles, allowedPermissions }) => {
  const { role_id, permissions } = useSelector(selectUser);
  const location = useLocation();

  return localStorage.getItem("isAuthenticated") === "true" ? (
    checkRole(allowedRoles, role_id) ||
    checkPermission(allowedPermissions, permissions) ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
