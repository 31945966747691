import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";
import { formatFacturaCobranza } from "../helpers/FormatFactura";

export const getFacturasCobranza = createAsyncThunk(
  "collection/getFacturasCobranza",
  async (data, thunkAPI) => {
    return await axios
      .get(url.concat(`api/cobranza/vendedor`), {
        params: data,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getAutoCompleteSeller = createAsyncThunk(
  "/api/cobranza/buscar/vendedor",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/usuarios/buscar/nombre`), {
        params: params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getCollectionScannedInvoice = createAsyncThunk(
  "/api/cobranza/escanear/factura",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/cobranza/factura/scan`), {
        params: params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getArchivingScannedInvoice = createAsyncThunk(
  "/api/archivar/escanear/factura",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/cobranza/archivar_factura/scan`), {
        params: params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getFormatoCobranza = createAsyncThunk(
  "api/cobranza/formato_cobranza",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/factura/formato_cobranza"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

const initialState = {
  cobranza: {
    vendedor: {
      id: 0,
      name: "",
      email: "",
      routes: [],
      totalFacturas: 0,
      totalNotasCredito: 0,
      lastCollectionDate: "",
    },
    facturas: [],
    notasCredito: [],
    documentos: [],
  },

  archivado: {
    facturas: [],
  },

  sellersList: [],

  isFetchingFacturasCobranza: false,
  isErrorFacturasCobranza: false,
  isSuccessFacturasCobranza: false,

  isFetchingVendedor: false,
  isErrorVendedor: false,
  isSuccessVendedor: false,

  isFetchingScanner: false,
  isErrorScanner: false,
  isSuccessScanner: false,

  isFetchingFormato: false,
  isErrorFormato: false,
  isSuccessFormato: false,

  showWarningAlert: false,
  dontShowAgain: false,
  alertType: "",
  errorMessage: "",
  successMessage: "",
  error: {
    code: "",
    message: "",
  },
};

export const cobranzaSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    clearStateFacturasCobranza: (state) => {
      state.isErrorFacturasCobranza = false;
      state.isSuccessFacturasCobranza = false;
      state.isFetchingFacturasCobranza = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = {
        code: "",
        message: "",
      };
    },
    clearStateCollectionScanner: (state) => {
      state.isErrorScanner = false;
      state.isSuccessScanner = false;
      state.isFetchingScanner = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = {
        code: "",
        message: "",
      };
      state.showWarningAlert = false;
    },
    clearStateFormato: (state) => {
      state.isErrorFormato = false;
      state.isSuccessFormato = false;
      state.isFetchingFormato = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = {
        code: "",
        message: "",
      };
    },
    clearStateArchivadoFacturas: (state) => {
      state.archivado.facturas = [];
    },
    toggleDontShowAgain: (state, action) => {
      state.dontShowAgain = action.payload;
    },
    removeInvoiceFromList: (state, action) => {
      let documentosArray = state.cobranza.documentos;
      let facturasArray = state.cobranza.facturas;
      let notasCreditoArray = state.cobranza.notasCredito;

      let index = documentosArray.findIndex(
        (documento) => documento.id === action.payload
      );

      if (index !== -1) {
        documentosArray.splice(index, 1);

        if (documentosArray[index]["tipo"] === "RV") {
          let index = facturasArray.findIndex(
            (factura) => factura.id === action.payload
          );
          facturasArray.splice(index, 1);
        }

        if (documentosArray[index]["tipo"] === "NC") {
          let index = notasCreditoArray.findIndex(
            (notaCredito) => notaCredito.id === action.payload
          );
          notasCreditoArray.splice(index, 1);
        }
      }
    },
    removeInvoiceFromArchivedList: (state, action) => {
      let facturasArray = state.archivado.facturas;

      let index = facturasArray.findIndex(
        (factura) => factura.id === action.payload.id
      );

      if (index !== -1) {
        facturasArray.splice(index, 1);
      }
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getFacturasCobranza.pending]: (state) => {
      state.isFetchingFacturasCobranza = true;
      state.isErrorFacturasCobranza = false;
      state.isSuccessFacturasCobranza = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = {
        code: "",
        message: "",
      };
    },
    [getFacturasCobranza.fulfilled]: (state, action) => {
      state.isFetchingFacturasCobranza = false;
      state.isSuccessFacturasCobranza = true;
      state.successMessage = action.payload.message;
      const formattedFacturas = action.payload.data.facturas.map((factura) =>
        formatFacturaCobranza(factura, "sellerDocuments")
      );
      const formattedNotasCredito = action.payload.data.notas_credito.map(
        (notaCredito) => formatFacturaCobranza(notaCredito, "sellerDocuments")
      );
      state.cobranza = {
        vendedor: {
          id: action.payload.data.vendedor.id,
          name: action.payload.data.vendedor.name,
          email: action.payload.data.vendedor.email,
          totalFacturas: action.payload.data.vendedor.total_facturas,
          totalNotasCredito: action.payload.data.vendedor.total_notas_credito,
          routes: action.payload.data.vendedor.routes,
          lastCollectionDate: action.payload.data.vendedor.last_collection_date,
        },
        facturas: formattedFacturas,
        notasCredito: formattedNotasCredito,
        documentos: [...formattedFacturas, ...formattedNotasCredito],
      };
    },
    [getFacturasCobranza.rejected]: (state, action) => {
      state.isFetchingFacturasCobranza = false;
      state.isErrorVendedor = true;
      state.error = action.payload;
      state.errorMessage = action.payload.message;
    },
    [getAutoCompleteSeller.pending]: (state) => {
      state.isFetchingVendedor = true;
      state.isErrorVendedor = false;
      state.isSuccessVendedor = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = {
        code: "",
        message: "",
      };
    },
    [getAutoCompleteSeller.fulfilled]: (state, action) => {
      state.isFetchingVendedor = false;
      state.isSuccessVendedor = true;
      state.successMessage = action.payload.message;
      let newArray = [];
      action.payload.data.forEach((user) => {
        const newValue = {
          id: user.id,
          name: user.name,
          email: user.email,
        };
        newArray.push(newValue);
      });
      state.sellersList = newArray;
    },
    [getAutoCompleteSeller.rejected]: (state, action) => {
      state.isFetchingVendedor = false;
      state.isErrorVendedor = true;
      state.error = action.payload;
      state.errorMessage = action.payload.message;
      state.seller_list = [
        {
          id: "",
          name: "",
          email: "",
        },
      ];
    },
    [getCollectionScannedInvoice.pending]: (state) => {
      state.isFetchingScanner = true;
      state.isErrorScanner = false;
      state.isSuccessScanner = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = {
        code: "",
        message: "",
      };
    },
    [getCollectionScannedInvoice.fulfilled]: (state, action) => {
      state.isFetchingScanner = false;
      state.isSuccessScanner = true;
      state.successMessage = action.payload.message;
      let newDocument = action.payload.data;

      let documentosArray = state.cobranza.documentos;
      let facturasArray = state.cobranza.facturas;
      let notasCreditoArray = state.cobranza.notasCredito;

      let index = documentosArray.findIndex(
        (documento) => documento.id === newDocument.id
      );

      // console.log(index, 'index')

      if (index !== -1) {
        // newDocument['enableDelete'] = false;
        newDocument["context"] = documentosArray[index]["context"];
        documentosArray.splice(index, 1);
        documentosArray.unshift(newDocument);

        if (newDocument["tipo"] === "RV") {
          let index = facturasArray.findIndex(
            (factura) => factura.id === newDocument.id
          );
          facturasArray.splice(index, 1);
          facturasArray.unshift(newDocument);
        }

        if (newDocument["tipo"] === "NC") {
          let index = notasCreditoArray.findIndex(
            (notaCredito) => notaCredito.id === newDocument.id
          );
          notasCreditoArray.splice(index, 1);
          notasCreditoArray.unshift(newDocument);
        }
      } else {
        // newDocument['enableDelete'] = true;
        documentosArray.unshift(newDocument);
        newDocument["context"] = "addedDocuments";

        if (newDocument["tipo"] === "RV") {
          facturasArray.unshift(newDocument);
        } else if (newDocument["tipo"] === "NC") {
          notasCreditoArray.unshift(newDocument);
        }
      }

      if (
        action.payload.message.toLowerCase().includes("validacion") &&
        !state.dontShowAgain
      ) {
        state.alertType = "warning";
        state.showWarningAlert = true;
      }

      state.cobranza.documentos = documentosArray;
    },
    [getCollectionScannedInvoice.rejected]: (state, action) => {
      state.isFetchingScanner = false;
      state.isErrorScanner = true;
      state.error = action.payload.error;
      state.errorMessage = action.payload.message;
      state.alertType = "error";
    },
    [getFormatoCobranza.fulfilled]: (state) => {
      state.isFetchingFormato = false;
      state.isSuccessFormato = true;
    },
    [getFormatoCobranza.pending]: (state) => {
      state.isFetchingFormato = true;
    },
    [getFormatoCobranza.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingFormato = false;
      state.isErrorFormato = true;
      state.errorMessage = error_message?.data
        ? error_message?.message + ". " + JSON.stringify(error_message.data)
        : error_message?.message
        ? error_message.message
        : "Error en el servidor responsiva";
    },
    [getArchivingScannedInvoice.pending]: (state) => {
      state.isFetchingScanner = true;
      state.isErrorScanner = false;
      state.isSuccessScanner = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.error = {
        code: "",
        message: "",
      };
    },
    [getArchivingScannedInvoice.fulfilled]: (state, action) => {
      state.isFetchingScanner = false;
      state.isSuccessScanner = true;
      state.successMessage = action.payload.message;
      let newDocument = action.payload.data;
      let facturasArray = state.archivado.facturas;

      let index = facturasArray.findIndex(
        (documento) => documento.id === newDocument.id
      );

      if (index !== -1) {
        facturasArray.splice(index, 1);
        facturasArray.unshift(newDocument);
      } else {
        facturasArray.unshift(newDocument);
      }

      state.archivado.facturas = facturasArray;
    },

    [getArchivingScannedInvoice.rejected]: (state, action) => {
      state.isFetchingScanner = false;
      state.isErrorScanner = true;
      state.error = action.payload.error;
      state.errorMessage = action.payload.message;
      state.alertType = "error";
    },
  },
});

export const {
  clearStateFacturasCobranza,
  clearStateCollectionScanner,
  toggleDontShowAgain,
  clearStateFormato,
  clearStateArchivadoFacturas,
  removeInvoiceFromArchivedList,
  removeInvoiceFromList,
  reset,
} = cobranzaSlice.actions;

export default cobranzaSlice.reducer;
