import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { CssBaseline, AppBar } from "@material-ui/core";
import { Typography, Toolbar } from "@material-ui/core";

const THEME = createTheme({
  typography: {
    fontFamily: `EMprint W05 Regular_web`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(9),
  },
  appBar: {
    position: "relative",
    backgroundColor: "#094b9b",
    color: "#fff"
  },
}));

const AccountsLayout = () => {
  const classes = useStyles();
  return (
    <div className="App">
      <ThemeProvider theme={THEME}>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Tracking de Facturas
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <Suspense fallback={<a>Cargando...</a>}>
            <Outlet />
          </Suspense>
        </main>
      </ThemeProvider>
    </div>
  );
};

export default AccountsLayout;
