import React from "react";
import Alert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";

function Message({ severity, children, clearState }) {
  const dispatch = useDispatch();
  return (
    <div>
      {severity === "error" ? (
        <Alert
          severity={severity}
          onClose={() => severity === "error" && dispatch(clearState())}
        >
          {children}
        </Alert>
      ) : (
        <Alert severity={severity}>{children}</Alert>
      )}
    </div>
  );
}

export default Message;
