import { DEPARTAMENTOS } from "../constants"

//recieves an array of allowed departments and the user's department id
//eg: allowedDepartmentsArray = ["SAC", "GER"] and userDepartmentId = 1
//check if the user's department is in the allowed departments array using the DEPARTAMENTOS constant
//return true if the user's department is in the allowed departments array
//return false if the user's department is not in the allowed departments array

export const checkDepartment = (allowedDepartmentsArray, userDepartmentId) => {
    const allowedDepartments = allowedDepartmentsArray.map((department) => {
        return DEPARTAMENTOS?.find((dept) => dept.label === department).value
    })
    return allowedDepartments.includes(userDepartmentId)
}