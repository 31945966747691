import React, { useState, useEffect } from "react";
import {
  Grid,
  CssBaseline,
  Paper,
  Avatar,
  Typography,
  LinearProgress,
  //Link,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Message from "../components/Message";
import FormInputEmail from "../form-components/FormInputEmail";
import FormInputPassword from "../form-components/FormInputPassword";
import "../styles/Login.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  selectUser,
  clearState,
  //logOut,
} from "../features/userSlice";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useNavigate, Link } from "react-router-dom";
import img from "../assets/portada.jpeg";
import img_cill from "../assets/portada_cill.jpg";
import { ResetStore } from "../auth/ResetStore";
// import { version } from "../../package.json";
import packageInfo from "../../package.json";

const version = packageInfo.version;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${
      process.env.REACT_APP_COMPANY === "CILLASA" ? img_cill : img
    })`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    dispatch(loginUser({ data: data }));
  };

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(selectUser);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      ResetStore(dispatch);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      navigate("/");
    }
  }, [isSuccess]);

  return (
    <Grid container component={"main"} className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>Iniciar sesión</h2>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} style={{ alignSelf: "center" }}>
              {isSuccess ? (
                <Grid item xs={12}>
                  <Message severity="success">¡Login exitoso!</Message>
                </Grid>
              ) : isError ? (
                <Grid item xs={12}>
                  <Message severity="error" clearState={clearState}>
                    {errorMessage}
                  </Message>
                </Grid>
              ) : (
                isFetching && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )
              )}
              <Grid item sm={12} md={12} lg={12}>
                <FormInputEmail
                  name={"email"}
                  control={control}
                  label={"E-Mail"}
                  req
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <FormInputPassword
                  name={"password"}
                  control={control}
                  label={"Contraseña"}
                  req
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <Button
                  className="button-login"
                  variant="contained"
                  color="primary"
                  type={"submit"}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid
            container
            style={{
              padding: "10px",
            }}
          >
            {/* <Grid
              item
              xs
            >
              <Link to="/" variant="body2">
                ¿No tienes cuenta? Solicita una
              </Link>
            </Grid> */}
            <Grid item xs>
              <Link to="/accounts/password/reset" variant="body2">
                Olvidé mi contraseña
              </Link>
            </Grid>
          </Grid>
        </div>
        <Typography variant="body2" color="textSecondary" align="center">
          {/* V {process.env.REACT_APP_VERSION} */}V {version}
        </Typography>
        {process.env.REACT_APP_ENV !== "PROD" && (
          <>
            <Typography variant="body2" color="textSecondary" align="center">
              SERVIDOR DE{" "}
              {process.env.REACT_APP_ENV === "QAS"
                ? "CALIDAD"
                : process.env.REACT_APP_ENV === "DEV"
                ? "DESARROLLO"
                : "PRODUCCIÓN"}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              {
                "Si deseas ir al servidor de produción, haz click en el siguiente enlace: "
              }
              {process.env.REACT_APP_COMPANY === "LUBTRAC" ? (
                <a href="https://facturas.lubtrac.com.mx">Productivo Lubtrac</a>
              ) : process.env.REACT_APP_COMPANY === "LUBTRAC_GT" ? (
                <a href="https://facturas.lubtrac.com.gt">
                  Productivo Lubtrac Guatemala
                </a>
              ) : (
                <a href="https://tracking.circulollantero.com.mx">
                  Tracking Cillasa
                </a>
              )}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Login;
