import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, CircularProgress } from "@material-ui/core";
import * as yup from "yup";
import FormInputPassword from "../form-components/FormInputPassword2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {
  selectUser,
  changePassword as changePasswordCall,
  clearStatePassword,
} from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Message from "./Message";

function PasswordChange({ changePassword, setChangePassword }) {
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, "La contraseña es muy corta - 6 caracteres mínimo")
      .max(8, "La contraseña es muy larga - 8 caracteres máximo")
      .matches(/^\d+$/, "La contraseña debe ser numerica")
      .required("La contraseña es requerida"),
    passwordConfirmation: yup
      .string()
      .required("Por favor confirme la nueva contraseña")
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden"),
  });
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    isFetchingPassword,
    isSuccessPassword,
    isErrorPassword,
    successMessage,
    errorMessage,
  } = useSelector(selectUser);

  const onSubmit = (data) => {
    let new_data = {
      new_password: data.password,
    };
    dispatch(changePasswordCall({ data: new_data }));
  };

  const handleClosePassword = () => {
    reset();
    setChangePassword(false);
    dispatch(clearStatePassword());
  };

  useEffect(() => {
    if (isSuccessPassword) {
      setTimeout(() => {
        handleClosePassword();
      }, 3000);
    }
  }, [successMessage]);

  return (
    <Dialog open={changePassword} onClose={handleClosePassword}>
      <DialogTitle
        id="alert-dialog-title"
        style={{ backgroundColor: "#1D5197", color: "#fff" }}
      >
        <a>Cambio de Contraseña</a>
      </DialogTitle>
      <DialogContent>
        <form id="change-password-dialog" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              style={{
                padding: "10px 15px",
                justifyContent: "center",
              }}
            >
              {isFetchingPassword ? (
                <>
                  <Typography>
                    Cambiando contraseña...
                    <CircularProgress />
                  </Typography>
                </>
              ) : (
                <>
                  {isSuccessPassword ? (
                    <Message severity="success">{successMessage}</Message>
                  ) : (
                    isErrorPassword && (
                      <Message severity="error" clearState={clearStatePassword}>
                        {errorMessage}
                      </Message>
                    )
                  )}
                  <Grid item xs={12}>
                    <FormInputPassword
                      name={"password"}
                      control={control}
                      label="Nueva contraseña"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputPassword
                      name={"passwordConfirmation"}
                      control={control}
                      label="Confirme la contraseña"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosePassword} color="primary">
          Cancelar
        </Button>
        <Button
          type="submit"
          form="change-password-dialog"
          color="primary"
          autoFocus
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordChange;
