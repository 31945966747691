import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";
// import { version } from "../../package.json";
import packageInfo from "../../package.json";

const version = packageInfo.version;

export const loginUser = createAsyncThunk(
  "users/Login",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/login"), data)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getAutoComplete = createAsyncThunk(
  "/api/usuarios/buscar/nombre",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/usuarios/buscar/nombre`), {
        params: params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const changePassword = createAsyncThunk(
  "/api/usuarios/contraseña",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat(`api/usuarios/contrasenha`), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getDrivers = createAsyncThunk(
  "/api/usuarios/delivery-drivers",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/usuarios`), {
        params: params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

// export const desactiveUser = createAsyncThunk(
//   "/api/usuarios/desactivar",
//   async ({ data }, thunkAPI) => {
//     return await axios
//       .put(url.concat(`api/usuarios/desactivar`), data, {
//         headers: {
//           Authorization:
//             "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
//         },
//       })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((e) => {
//         return thunkAPI.rejectWithValue(e.response.data);
//       });
//   }
// );

const initialState = {
  id: "",
  email: "",
  name: "",
  department_id: "",
  subsidiary_id: "",
  role_id: "",
  role: "",
  permissions: [],
  token: "",

  logo: "",
  subsidiaries: [],
  departments: [],
  sacCategories: [],
  almCategories: [],
  status: [],

  isFetching: false,
  isSuccess: false,
  isError: false,

  isFetchingPassword: false,
  isSuccessPassword: false,
  isErrorPassword: false,

  isFetchingComplete: false,
  isSuccessComplete: false,
  isErrorComplete: false,

  isFetchingDesactivar: false,
  isSuccessDesactivar: false,
  isErrorDesactivar: false,

  isFetchingDrivers: false,
  isSuccessDrivers: false,
  isErrorUsers: false,

  successMessage: "",
  errorMessage: "",

  deliveryDrivers: [],

  user_list: [
    {
      id: "",
      name: "",
      email: "",
    },
  ],

  // appVersion: "6",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    clearCompletar: (state) => {
      state.isFetchingComplete = false;
      state.isSuccessComplete = false;
      state.isErrorComplete = false;
      return state;
    },
    clearStatePassword: (state) => {
      state.isFetchingPassword = false;
      state.isSuccessPassword = false;
      state.isErrorPassword = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateDrivers: (state) => {
      state.isFetchingDrivers = false;
      state.isSuccessDrivers = false;
      state.isErrorDrivers = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    // clearStateDesactivar: (state) => {
    //   state.isFetchingDesactivar = false;
    //   state.isSuccessDesactivar = false;
    //   state.isErrorDesactivar = false;
    //   state.successMessage = "";
    //   state.errorMessage = "";
    //   return state;
    // },
    reset: () => initialState,
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.id = payload.user.id;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.department_id = payload.user.department_id;
      state.subsidiary_id = payload.user.subsidiary_id;
      state.role_id = payload.user.role_id;
      state.token = payload?.token;
      state.logo = payload?.logo;

      if (payload.user?.permissions.length > 0) {
        state.permissions = payload.user.permissions.map((permission) => {
          return permission.name;
        });
      }

      if (payload?.subsidiaries.length > 0) {
        state.subsidiaries = payload?.subsidiaries.map((subsidiary) => {
          return {
            value: subsidiary.id,
            label: subsidiary.name,
          };
        });
      }

      if (payload?.departments.length > 0) {
        state.departments = payload?.departments.map((department) => {
          return {
            value: department.id,
            label: department.name,
          };
        });
      }

      if (payload?.status.length > 0) {
        state.status = payload?.status.map((status) => {
          return {
            value: status.id,
            label: status.name,
          };
        });
      }

      if (payload?.sacCategories.length > 0) {
        state.sacCategories = payload?.sacCategories.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        });
      }

      if (payload?.almCategories.length > 0) {
        state.almCategories = payload?.almCategories.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        });
      }

      localStorage.setItem("token", payload.token);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("version", version);
      localStorage.setItem(
        "reduxVersion",
        process.env.REACT_APP_REDUX_STORE_VERSION
      );
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getAutoComplete.fulfilled]: (state, { payload }) => {
      state.isFetchingComplete = false;
      state.isSuccessComplete = true;
      state.isErrorComplete = false;
      let newArray = [];
      payload.data.forEach((user) => {
        const newValue = {
          id: user.id,
          name: user.name,
          email: user.email,
        };
        newArray.push(newValue);
      });
      state.user_list = newArray;
    },
    [getAutoComplete.pending]: (state) => {
      state.isFetchingComplete = true;
    },
    [getAutoComplete.rejected]: (state, { payload }) => {
      state.isFetchingComplete = false;
      state.isSuccessComplete = false;
      state.isErrorComplete = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
      state.user_list = [
        {
          id: "",
          name: "",
          email: "",
        },
      ];
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.isFetchingPassword = false;
      state.isSuccessPassword = true;
      state.successMessage = payload.message;
    },
    [changePassword.pending]: (state) => {
      state.isFetchingPassword = true;
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.isFetchingPassword = false;
      state.isErrorPassword = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },

    [getDrivers.fulfilled]: (state, { payload }) => {
      state.isFetchingDrivers = false;
      state.isSuccessDrivers = true;
      if (payload.data.length === 0) {
        state.deliveryDrivers = [];
      } else {
        const new_array = payload.data.map((driver) => {
          return driver.name;
        });
        state.deliveryDrivers = new_array;
      }
    },
    [getDrivers.pending]: (state) => {
      state.isFetchingDrivers = true;
    },
    [getDrivers.rejected]: (state, { payload }) => {
      state.isFetchingDrivers = false;
      state.isErrorDrivers = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    // [desactiveUser.fulfilled]: (state, { payload }) => {
    //   state.isFetchingDesactivar = false;
    //   state.isSuccessDesactivar = true;
    //   state.successMessage = payload.message;
    // },
    // [desactiveUser.pending]: (state) => {
    //   state.isFetchingDesactivar = true;
    // },
    // [desactiveUser.rejected]: (state, { payload }) => {
    //   state.isFetchingDesactivar = false;
    //   state.isErrorDesactivar = true;
    //   state.errorMessage = payload?.message
    //     ? payload?.data
    //       ? payload.message + ". " + JSON.stringify(payload.data)
    //       : payload.message
    //     : "Error en el servidor";
    // },
  },
});

export const { clearState, reset, clearStatePassword, clearStateDrivers } =
  userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
