import { useState, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import NavbarV2 from "./NavbarV2";
import MUIDrawer from "./MUIDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

const THEME = createTheme({
  typography: {
    fontFamily: `EMprint W05 Regular_web`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(9),
  },
}));

const Layout = () => {
  const classes = useStyles();
  const [showNav, setShowNav] = useState(false);
  const { pathname } = useLocation();
  const drawerWidth = 300;

  const pathnames = pathname.split("/").filter((x) => x);
  const path_split = pathnames[0].replaceAll("_", " ");
  const path = path_split.charAt(0).toUpperCase() + path_split.slice(1);

  return (
    <div className="App">
      <ThemeProvider theme={THEME}>
        <NavbarV2
          showNav={showNav}
          setShowNav={setShowNav}
          drawerWidth={drawerWidth}
          path={path}
          //location={location}
        />
        <MUIDrawer
          showNav={showNav}
          setShowNav={setShowNav}
          pathnames={pathnames}
          //location={setLocation}
        />
        <main className={classes.content} onClick={() => setShowNav(false)}>
          <Suspense fallback={<a>Cargando...</a>}>
            <Outlet />
          </Suspense>
        </main>
        {/* <div className="main" onClick={() => setShowNav(false)}></div> */}
        {/* <Header showNav={showNav} setShowNav={setShowNav} />
        <Navbar show={showNav} setShowNav={setShowNav} />
        <Grid style= {{
          margin: "5px",
        }}>
          <Grid container direction="row">
            <Grid item xs={6}>
              <strong style={{ color: "#bdbdbd", margin: "5px" }}>
                2022 Lubtrac Solutions.
              </strong>
            </Grid>
            <Grid item xs={6}>
              <strong style={{ color: "#bdbdbd" }}>
                {process.env.REACT_APP_VERSION}
              </strong>
            </Grid>
          </Grid>
        </Grid> */}
      </ThemeProvider>
    </div>
  );
};

export default Layout;
