import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";

export const generateCode = createAsyncThunk(
  "account/generateCode",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/password/email"), data)
      .then((response) => {
        return { ...response.data, email: data.email };
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const validateCode = createAsyncThunk(
  "account/validateCode",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/password/code/check"), data)
      .then((response) => {
        return { ...response.data, code: data.code };
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const changePasswordCall = createAsyncThunk(
  "account/passwordReset",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/password/reset"), data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

const initialState = {
  isFetchingCode: false,
  isSuccessCode: false,
  isErrorCode: false,

  isFetchingValidate: false,
  isSuccessValidate: false,
  isErrorValidate: false,

  isFetchingChange: false,
  isSuccessChange: false,
  isErrorChange: false,

  email: "",
  code: "",

  errorMessage: "",
  successMessage: "",
};

export const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    clearStateCode: (state) => {
      state.isFetchingCode = false;
      state.isSuccessCode = false;
      state.isErrorCode = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    clearStateValidate: (state) => {
      state.isFetchingValidate = false;
      state.isSuccessValidate = false;
      state.isErrorValidate = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    reset: () => initialState,
  },
  extraReducers: {
    [generateCode.fulfilled]: (state, { payload }) => {
      state.isFetchingCode = false;
      state.isSuccessCode = true;
      state.successMessage = payload.message;
      state.email = payload.email;
    },
    [generateCode.pending]: (state) => {
      state.isFetchingCode = true;
    },
    [generateCode.rejected]: (state, { payload }) => {
      state.isFetchingCode = false;
      state.isErrorCode = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [validateCode.fulfilled]: (state, { payload }) => {
      state.isFetchingValidate = false;
      state.isSuccessValidate = true;
      state.code = payload.code;
      state.successMessage = payload.message;
    },
    [validateCode.pending]: (state) => {
      state.isFetchingValidate = true;
    },
    [validateCode.rejected]: (state, { payload }) => {
      state.isFetchingValidate = false;
      state.isErrorValidate = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [changePasswordCall.fulfilled]: (state, { payload }) => {
      state.isFetchingChange = false;
      state.isSuccessChange = true;
      state.successMessage = payload.message;
    },
    [changePasswordCall.pending]: (state) => {
      state.isFetchingChange = true;
    },
    [changePasswordCall.rejected]: (state, { payload }) => {
      state.isFetchingChange = false;
      state.isErrorChange = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
  },
});

export const { clearStateCode, clearStateValidate, reset } = accountSlice.actions;

export const selectAccount = (state) => state.account;

export default accountSlice.reducer;
