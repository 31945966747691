import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function ConfirmationDialog({
  open,
  title,
  handleClose,
  closeText,
  children,
  handleAccept,
  acceptText,
  paperStyle,
}) {
  const useStyles = makeStyles((theme) => ({
    dialogPaper: paperStyle
      ? paperStyle
      : {
        minHeight: "35vh",
        maxHeight: "90vh",
        maxWidth: "520px",
      },
  }));
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ backgroundColor: "#1D5197", color: "#fff" }}
        >
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {handleClose ? (
            <Button onClick={handleClose} color="primary">
              {closeText ? closeText : "Cancelar"}
            </Button>
          ) : null}
          {
            handleAccept ? (
              <Button onClick={handleAccept} color="primary" autoFocus>
                {acceptText ? acceptText : "Aceptar"}
              </Button>
            ) : null
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmationDialog;
