import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";
import { formatFactura, formatFacturaCobranza } from "../helpers/FormatFactura";

export const getPago = createAsyncThunk(
  "/api/cartera/bapi/valida_cobranza",
  async ({ factura_id }, thunkAPI) => {
    return await axios
      .get(url.concat("api/cartera/bapi"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
          "Content-Type": "application/json",
        },
        params: {
          factura_id: factura_id,
          detalle: 0,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem("isAuthenticated", "false");
        //   window.location.reload();
        // }
        return thunkAPI.rejectWithValue(err);
      });
  }
);

export const categoriaAlm = createAsyncThunk(
  "/api/factura/categoria/alm",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/factura/categoria/alm"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err);
      });
  }
);

//Facturas cliente-vendedor recoge
export const facturaExpress = createAsyncThunk(
  "/api/factura/recoge",
  async (thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/recoge"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const buscarFactura = createAsyncThunk(
  "/api/facturas/busqueda",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/facturas"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: params?.export ? "blob" : "json",
      })
      .then((response) => {
        if (params?.export && params.export === 1) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "facturas " + Date.now() + ".xlsx");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          response.data.exported = 1;
          response.data = [];
        }

        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

/**
 * !Deprecated since version 2.4.0
 *
 */

// export const buscarFacturaNoMov = createAsyncThunk(
//   "/api/facturas/cliente/no_mov",
//   async (
//     {
//       user_id,
//       page,
//       rowsPerPage,
//       activa,
//       ultimo_movimiento_dias,
//       fecha_inicio,
//     },
//     thunkAPI
//   ) => {
//     return await axios
//       .get(url.concat("api/facturas"), {
//         params: {
//           user_id: user_id,
//           activa: activa,
//           ultimo_movimiento_dias: ultimo_movimiento_dias,
//           fecha_inicio: fecha_inicio,
//           page: page,
//           perPage: rowsPerPage,
//           receptor_data: 1,
//         },
//         headers: {
//           Authorization:
//             "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
//         },
//       })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((e) => {
//         if (e.response.status === 401) {
//           localStorage.setItem("isAuthenticated", "false");
//           window.location.reload();
//         }
//         return thunkAPI.rejectWithValue(e.response.data);
//       });
//   }
// );

export const buscarFacturaNoMov = createAsyncThunk(
  "/api/factura/nomovimiento",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/nomovimiento"), {
        params: params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: params?.export ? "blob" : "json",
      })
      .then((response) => {
        if (params?.export && params.export === 1) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            "No Movimiento " + Date.now() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          response.data.exported = 1;
          response.data = [];
        }

        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const busquedaScan = createAsyncThunk(
  "/api/factura/detalleqr/busqueda",
  async ({ params, modulo }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/detalleqr"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        params: params,
      })
      .then((response) => {
        response.data.modulo = modulo;
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

/**
 * ? Se cambia a routeSlice since v 2.5.6
 *
 */
// export const buscarFacturaRoute = createAsyncThunk(
//   "/api/factura/detalleqr/busqueda/route",
//   async ({ params }, thunkAPI) => {
//     return await axios
//       .get(url.concat("api/factura/detalleqr"), {
//         headers: {
//           Authorization:
//             "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
//         },
//         params: params,
//       })
//       .then((response) => {
//         response.data.facturas = params.facturas;
//         return response.data;
//       })
//       .catch((e) => {
//         if (e.response.status === 401) {
//           localStorage.setItem("isAuthenticated", "false");
//           window.location.reload();
//         }
//         return thunkAPI.rejectWithValue(e.response.data);
//       });
//   }
// );

export const getUserFacturas = createAsyncThunk(
  "/api/factura/misfacturas",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/misfacturas"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: params?.export ? "blob" : "json",
      })
      .then((response) => {
        if (params?.export && params.export === 1) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "facturas " + Date.now() + ".xlsx");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          response.data.exported = 1;
          response.data = [];
        }
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getFacturasDepartamento = createAsyncThunk(
  "/api/factura/midepartamento",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/midepartamento"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: params?.export ? "blob" : "json",
      })
      .then((response) => {
        if (params?.export && params.export === 1) {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "facturas " + Date.now() + ".xlsx");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          response.data.exported = 1;
          response.data = [];
        }
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);


export const getCarteraCollection = createAsyncThunk(
  "api/cartera/factura_id/collection",
  async ({ factura_folio, fecha }, thunkAPI) => {
    return await axios
      .get(url.concat("api/cartera"), {
        params: {
          factura_folio: factura_folio,
          fecha: fecha,
        },
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const getDocumentosFactura = createAsyncThunk(
  "api/factura/documents",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/documents"), {
        params: {
          facturaId: params.facturaId,
        },
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/zip" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", params.folioFactura);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

const initialState = {
  invoicesToSupply: [],
  user_facturas: {
    data: [],
  },

  // route_number: "",
  facturas_vendedor: [],
  facturas_encontradas: {
    data: [],
  },

  facturas_encontradas_collection: "", //factura escaneada
  Pago: "",
  table_collections_data: [], //arreglo de las facturas que alimenta la tabla de CollectionInvoices
  selected_rows: [],
  isFill: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  isFetchingBusqueda: false,
  isSuccessBusqueda: false,
  isErrorBusqueda: false,
  isFetchingPago: false,
  isSuccessPago: false,
  isErrorPago: false,
  checkCobranza: false,
  isSuccessCobranza: false,
  isFetchingCobranza: false,
  isErrorCobranza: false,
  isSuccessCategoria: false,
  isFetchingCategoria: false,
  isErrorCategoria: false,

  isFetchingDocumentos: false,
  isSuccessDocumentos: false,
  isErrorDocumentos: false,

  /**
   * Facturas escaneadas del modulo Leo y Checo
   *
   */
  scannedLCInvoices: [],
  scannedInvoice: [],
  showWarning: false,
  showError: false,

  Cobranza: [],
  successMessage: "",
  errorMessage: "",
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    clearStateBusqueda: (state) => {
      state.isErrorBusqueda = false;
      state.isSuccessBusqueda = false;
      state.isFetchingBusqueda = false;
      state.errorMessage = "";
      return state;
    },
    clearBusqueda: (state) => {
      state.facturas_encontradas = {
        data: [],
      };
    },
    updateTableCollections: (state, { payload }) => {
      state.table_collections_data = payload;
      state.isFill = false;
      return state;
    },
    updateCategoriaAlm: (state, { payload }) => {
      let detalleTable = state.table_collections_data;
      const index = detalleTable.findIndex(
        (factura) => factura.ID === payload.data.factura_id
      );
      if (index >= 0) {
        detalleTable[index]["Categoria"] = payload.data.categoria;
        if (payload.data.categoria !== "Reenvio") {
          for (let i = 0; i < detalleTable[index]["Productos"].length; i++) {
            detalleTable[index]["Productos"][i]["entregado"] = 1;
          }
        } else {
          if (typeof payload.data.entregas === "undefined") {
            for (let i = 0; i < detalleTable[index]["Productos"].length; i++) {
              detalleTable[index]["Observaciones_almacen"] =
                payload.data.observaciones;
              detalleTable[index]["Productos"][i]["entregado"] = 0;
            }
          } else {
            for (let i = 0; i < detalleTable[index]["Productos"].length; i++) {
              detalleTable[index]["Observaciones_almacen"] =
                payload.data.observaciones;
              for (let c = 0; c < payload.data.entregas.length; c++) {
                if (
                  detalleTable[index]["Productos"][i]["noidentificacion"] ===
                  payload.data.entregas[c]["noidentificacion"]
                ) {
                  detalleTable[index]["Productos"][i]["entregado"] = 0;
                  detalleTable[index]["Productos"][i]["volumen_entregado"] =
                    payload?.data?.entregas[c]["volumen_entregado"] !== ""
                      ? payload?.data?.entregas[c]["volumen_entregado"]
                      : "0";
                } else {
                  detalleTable[index]["Productos"][i]["entregado"] = 0;
                  detalleTable[index]["Productos"][i]["volumen_entregado"] =
                    "0";
                }
              }
            }
          }
        }
      }
      state.table_collections_data = detalleTable;
      return state;
    },
    updateSelectedRows: (state, { payload }) => {
      state.selected_rows = payload;
      return state;
    },
    clearCollections: (state) => {
      state.table_collections_data = [];
      state.facturas_encontradas_collection = "";
      state.facturas_vendedor = [];
      state.selected_rows = [];
      state.isFill = false;
      return state;
    },
    clearSelectedRows: (state) => {
      state.selected_rows = [];
      return state;
    },
    clearStatePago: (state) => {
      state.isFetchingPago = false;
      state.isSuccessPago = false;
      state.isErrorPago = false;
      state.Pago = "";
      return state;
    },
    clearStateCobranza: (state) => {
      state.isFetchingCobranza = false;
      state.isSuccessCobranza = false;
      state.isErrorCobranza = false;
      state.checkCobranza = false;
      return state;
    },
    clearStateCategoria: (state) => {
      state.isFetchingCategoria = false;
      state.isSuccessCategoria = false;
      state.isErrorCategoria = false;
      state.errorMessage = "";
      return state;
    },
    clearStateDocumentos: (state) => {
      state.isFetchingDocumentos = false;
      state.isSuccessDocumentos = false;
      state.isErrorDocumentos = false;
      state.errorMessage = "";
      return state;
    },
    removeInvoiceCollection: (state, { payload }) => {
      const newArray = state.table_collections_data.filter(
        (factura) => factura.ID !== payload
      );
      state.table_collections_data = newArray;
      return state;
    },
    reset: () => initialState,

    /**
     * Funciones para el modulo Leo y Checo
     *
     */
    clearStateWarning: (state) => {
      state.showError = false;
      state.showWarning = false;
      state.scannedInvoice = [];
      return state;
    },
    clearLCInvoices: (state) => {
      state.scannedLCInvoices = [];
      return state;
    },
    removeItemLC: (state, { payload }) => {
      const newArray = state.scannedLCInvoices.filter(
        (scan) => scan.id !== payload
      );
      state.scannedLCInvoices = newArray;
    },
  },
  extraReducers: {
    [facturaExpress.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;

      let formattedInvoices = payload.data.map((invoice) =>
        formatFactura(invoice)
      )

      state.invoicesToSupply = formattedInvoices;
    },
    [facturaExpress.pending]: (state) => {
      state.isFetching = true;
      // state.invoicesToSupply = [];
    },
    [facturaExpress.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      // state.invoicesToSupply = [];
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [buscarFactura.fulfilled]: (state, { payload }) => {
      state.isFetchingBusqueda = false;

      if (payload?.data) {
        if (payload.data.data.length < 1) {
          state.isErrorBusqueda = true;
          state.errorMessage = "No se ha encontrado ninguna factura.";
          state.facturas_encontradas = {
            data: [],
            total: 0,
            current_page: 1,
            per_page: 10,
          };
        }
        if (payload.data.data.length >= 1) {
          state.isSuccessBusqueda = true;
          state.isErrorBusqueda = false;
          state.facturas_encontradas = {
            data: payload.data.data,
            total: payload.data.total,
            current_page: payload.data.current_page - 1,
            per_page: payload.data.per_page,
          };
        }
      }
    },
    [buscarFactura.pending]: (state, { payload }) => {
      state.isFetchingBusqueda = true;
    },
    [buscarFactura.rejected]: (state, { payload }) => {
      state.isFetchingBusqueda = false;
      state.isErrorBusqueda = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },

    // [buscarFacturaRoute.fulfilled]: (state, { payload }) => {
    //   state.isFetchingBusqueda = false;
    //   const facturas = payload.facturas;

    //   const index = facturas.findIndex(
    //     (fac, index) => fac.Factura === payload.data.folio
    //   );
    //   if (index >= 0) {
    //     if (facturas[index].Status === "Escaneada") {
    //       state.isErrorBusqueda = true;
    //       state.errorMessage = "Esta factura ya ha sido escaneada.";
    //     }
    //     if (facturas[index].Status === "No escaneada") {
    //       facturas[index].Status = "Escaneada";
    //       let element = facturas[index];
    //       facturas.splice(index, 1);
    //       facturas.unshift(element);
    //       state.table_collections_data = facturas;
    //       state.facturas_encontradas_collection = payload.data;
    //       state.isSuccessBusqueda = true;
    //     }
    //   } else {
    //     state.isErrorBusqueda = true;
    //     state.errorMessage = "La factura escaneada no pertenece a esta ruta.";
    //   }
    // },
    // [buscarFacturaRoute.pending]: (state) => {
    //   state.isFetchingBusqueda = true;
    // },
    // [buscarFacturaRoute.rejected]: (state, { payload }) => {
    //   state.isFetchingBusqueda = false;
    //   state.isErrorBusqueda = true;
    //   state.errorMessage = payload?.message
    //     ? payload?.data
    //       ? payload.message + ". " + JSON.stringify(payload.data)
    //       : payload.message
    //     : "Error en el servidor";
    // },
    [buscarFacturaNoMov.fulfilled]: (state, { payload }) => {
      state.isFetchingBusqueda = false;
      state.isSuccessBusqueda = true;
      if (payload?.data) {
        if (payload.data.data.length < 1) {
          state.isErrorBusqueda = true;
          state.errorMessage = "No se han encontrado facturas.";
          state.facturas_encontradas = {
            data: [],
          };
        }
        if (payload.data.data.length >= 1) {
          state.facturas_encontradas = payload.data;
        }
      }
    },
    [buscarFacturaNoMov.pending]: (state) => {
      state.isFetchingBusqueda = true;
    },
    [buscarFacturaNoMov.rejected]: (state, { payload }) => {
      state.isFetchingBusqueda = false;
      state.isErrorBusqueda = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [busquedaScan.fulfilled]: (state, { payload }) => {
      state.isFetchingBusqueda = false;
      state.isSuccessBusqueda = true;
      if (payload?.modulo && payload?.modulo === "leo_y_checo") {
        const newArray = state.scannedLCInvoices;
        if (payload.data) {
          let newInvoice = payload.data;
          let index = newArray.findIndex((scan) => scan.id === newInvoice.id);
          if (index < 0) {
            /**
             * Checamos si no hay entregas parciales
             */

            if (newInvoice?.detalle && newInvoice?.detalle.length > 0) {
              const entregadosCompletos = newInvoice.detalle.every(
                (item) => item.entregado === 1
              );
              const entregasParciales = newInvoice.detalle.some(
                (item) => item.volumen_entregado > 0 && item.entregado === 0
              );

              if (!entregadosCompletos) {
                let new_invoice = {
                  id: newInvoice.id,
                  folio: newInvoice.folio,
                  pedido: newInvoice?.complementos[0]
                    ? newInvoice.complementos[0].pedido_interno
                    : "N/A",
                  tipodecomprobante:
                    newInvoice.tipodecomprobante === "I"
                      ? "RV"
                      : newInvoice.tipodecomprobante === "E"
                        ? "NC"
                        : "R",
                  fecha: newInvoice.fecha,
                  clave_receptor: newInvoice.receptor.clave_receptor,
                  nombre_receptor: newInvoice?.receptor?.nombre
                    ? newInvoice.receptor.nombre
                    : "N/A",
                  categoria: newInvoice.categoria
                    ? newInvoice.categoria
                    : "Sin categoría de SAC",
                  categoria_alm: newInvoice.categoria_alm
                    ? newInvoice.categoria_alm
                    : "Sin categoría de ALM",
                  status_id: newInvoice?.status?.id,
                  status: newInvoice?.status?.name,
                  total: newInvoice.total,
                  observaciones: newInvoice?.complementos[0]?.observaciones
                    ? newInvoice.complementos[0].observaciones
                    : "N/A",
                  productos: newInvoice?.detalle ? newInvoice?.detalle : [],
                };
                if (entregasParciales) {
                  state.scannedInvoice = new_invoice;
                  state.showWarning = true;
                }
                newArray.unshift(new_invoice);
              } else {
                state.showError = true;
              }
            }
          }
        }
      } else {
        state.facturas_encontradas = payload.data;
      }
      // state.facturas_encontradas = payload.data;
    },
    [busquedaScan.pending]: (state) => {
      state.isFetchingBusqueda = true;
    },
    [busquedaScan.rejected]: (state, { payload }) => {
      state.isFetchingBusqueda = false;
      state.isErrorBusqueda = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getUserFacturas.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      if (payload?.data) {
        if (payload.data?.facturas) {
          state.user_facturas = payload.data.facturas;
        }
      }
    },
    [getUserFacturas.pending]: (state) => {
      state.isFetching = true;
      //state.user_facturas.data = [];
    },
    [getUserFacturas.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [categoriaAlm.fulfilled]: (state, { payload }) => {
      state.isFetchingCategoria = false;
      state.isSuccessCategoria = true;
      const newArray = state.table_collections_data;
      const index = newArray.findIndex(
        (fac, index) => fac.ID === payload.data.data.factura_id
      );
      newArray[index].Categoria = payload.data.data.new_category;
      state.table_collections_data = newArray;
    },
    [categoriaAlm.pending]: (state) => {
      state.isFetchingCategoria = true;
    },
    [categoriaAlm.rejected]: (state, { payload }) => {
      state.isFetchingCategoria = false;
      state.isErrorCategoria = true;
      state.errorMessage =
        typeof payload.message !== "undefined"
          ? payload.message
          : "Ha ocurrido un error al cambiar categoría de almacén";
    },
    [getFacturasDepartamento.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      if (payload?.data) {
        state.user_facturas = payload.data;
      }
    },
    [getFacturasDepartamento.pending]: (state) => {
      state.isFetching = true;
      //state.user_facturas.data = [];
    },
    [getFacturasDepartamento.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getPago.fulfilled]: (state, { payload }) => {
      state.isFetchingPago = false;
      state.isSuccessPago = true;
      state.isErrorPago = false;
      const facturas = state.table_collections_data;
      const index = facturas.findIndex(
        (fac) => fac.Factura === payload.data?.data?.factura
      );
      if (index >= 0) {
        if (facturas[index]["Escaneado/No Escaneado"] === 1) {
          facturas[index].Saldo = payload.data?.data?.saldo;
          facturas[index].Credito = payload.data?.data?.credito;
          facturas[index].Demora = payload.data?.data?.demora;
          facturas[index].Status = payload.data?.data?.status;
          // facturas[index].Moneda = payload.data?.data?.moneda;
        }
        state.table_collections_data = facturas;
      }
    },
    [getPago.pending]: (state) => {
      state.isFetchingPago = true;
    },
    [getPago.rejected]: (state, { payload }) => {
      state.isErrorPago = true;
      state.isFetchingPago = false;
      state.isSuccessPago = false;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getCarteraCollection.fulfilled]: (state, { payload }) => {
      state.isSuccessCobranza = true;
      state.isFetchingCobranza = false;
      state.isErrorCobranza = false;
      const facturas = state.table_collections_data;
      const index = facturas.findIndex(
        (fac, index) =>
          fac.ID ===
          payload.data.lista_de_pagos[payload.data.lista_de_pagos.length - 1]
            .factura_id
      );
      if (index >= 0) {
        if (facturas[index].Status === 1) {
          facturas[index].Saldo =
            payload.data.lista_de_pagos[
              payload.data.lista_de_pagos.length - 1
            ].pago;
          facturas[index].Credito = state.facturas_encontradas_collection
            .complemento[0].dias_pago
            ? state.facturas_encontradas_collection.complemento[0].dias_pago
            : 0;
          facturas[index].Demora =
            payload.data.lista_de_pagos[
              payload.data.lista_de_pagos.length - 1
            ].demora;
          state.checkCobranza = false;
        }
      }
    },
    [getCarteraCollection.pending]: (state) => {
      state.isFetchingCobranza = true;
    },
    [getCarteraCollection.rejected]: (state, { payload }) => {
      state.isFetchingCobranza = false;
      state.isSuccessCobranza = false;
      state.isErrorCobranza = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";

      const facturas = state.table_collections_data;
      const index = facturas.findIndex(
        (fac, index) => fac.ID === state.facturas_encontradas_collection.id
      );
      if (index >= 0) {
        if (facturas[index].Status === 1) {
          facturas[index].Saldo = "N/A";
          facturas[index].Tipo = "N/A";
          facturas[index].Credito = "N/A";
          facturas[index].Demora = "N/A";
          state.checkCobranza = false;
        }
      }
    },
    [getDocumentosFactura.fulfilled]: (state, { payload }) => {
      state.isFetchingDocumentos = false;
      state.isSuccessDocumentos = true;
    },
    [getDocumentosFactura.pending]: (state) => {
      state.isFetchingDocumentos = true;
    },
    [getDocumentosFactura.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingDocumentos = false;
      state.isErrorDocumentos = true;
      state.errorMessage = error_message?.data
        ? error_message?.message + ": " + JSON.stringify(error_message.data)
        : error_message?.message
          ? error_message.message
          : "Error en el servidor responsiva";
    },
  },
});

export const {
  clearState,
  clearStateBusqueda,
  clearBusqueda,
  updateTableCollections,
  updateSelectedRows,
  updateCategoriaAlm,
  clearCollections,
  clearSelectedRows,
  clearStatePago,
  clearStateCobranza,
  clearStateCategoria,
  clearStateFormato,
  clearStateDocumentos,
  clearLCInvoices,
  clearStateWarning,
  removeInvoiceCollection,
  removeItemLC,
  reset,
} = invoiceSlice.actions;

export const selectInvoice = (state) => state.invoice;

export default invoiceSlice.reducer;
