import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";

export const getPortales = createAsyncThunk(
  "/api/portales",
  async (thunkAPI) => {
    return await axios
      .get(url.concat("api/portales"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const createPortales = createAsyncThunk(
  "/api/portal/post",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/portal/post"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const updatePortales = createAsyncThunk(
  "/api/portal/post/update",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/portal/post"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const deletePortales = createAsyncThunk(
  "/api/portal/eliminar/{portal_id}",
  async ({ portal_id }, thunkAPI) => {
    return await axios
      .delete(url.concat(`api/portal/eliminar/${portal_id}`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        response.data.portal_id = portal_id;
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getAutoCompleteReceptor = createAsyncThunk(
  "/api/portal/buscar_cliente/nombre/{receptor_name}",
  async ({ receptor_name }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/portal/buscar_cliente/nombre/${receptor_name}`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

const initialState = {
  portales: [
    {
      id: "",
      name: "",
      cuentas_receptores: "",
      url: "",
      user_portal: "",
      password_portal: "",
      observations: "",
      subsidiary_name: "",
      department_name: "",
      is_handling_fee: false,
    },
  ],
  isFetching: false,
  isSuccess: false,
  isError: false,

  isFetchingCreate: false,
  isSuccessCreate: false,
  isErrorCreate: false,

  isFetchingUpdate: false,
  isSuccessUpdate: false,
  isErrorUpdate: false,

  isFetchingDelete: false,
  isSuccessDelete: false,
  isErrorDelete: false,

  isFetchingReceptor: false,
  isSuccessReceptor: false,
  isErrorReceptor: false,

  receptor_list: [
    {
      id: "",
      nombre: "",
    },
  ],

  errorMessage: "",
  successMessage: "",

  errorDeleteMessage: "",
  errorUpdateMessage: "",
  errorCreateMessage: "",
};

export const portalesSlice = createSlice({
  name: "portales",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    clearStateCreate: (state) => {
      state.isFetchingCreate = false;
      state.isSuccessCreate = false;
      state.isErrorCreate = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearStateUpdate: (state) => {
      state.isFetchingUpdate = false;
      state.isSuccessUpdate = false;
      state.isErrorUpdate = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearStateDelete: (state) => {
      state.isFetchingDelete = false;
      state.isSuccessDelete = false;
      state.isErrorDelete = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearStateReceptores: (state) => {
      state.isFetchingReceptor = false;
      state.isSuccessReceptor = false;
      state.isErrorReceptor = false;
      return state;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getPortales.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.portales = payload.data.map((portal) => {
        return {
          ...portal,
          cuentas_receptores: portal.receptores
            .map((receptor) => receptor.clave_receptor)
            .join(", "),
          subsidiary_name: portal.subsidiary.name,
          department_name: portal.department.name,
        };
      });
    },
    [getPortales.pending]: (state) => {
      state.isFetching = true;
    },
    [getPortales.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message
        ? payload?.message
        : "Error en el servidor";
    },
    [updatePortales.fulfilled]: (state, { payload }) => {
      state.isFetchingUpdate = false;
      state.isSuccessUpdate = true;
      state.successMessage = payload.message;

      const updatedPortal = {
        ...payload.data,
        cuentas_receptores: payload.data.receptores
          .map((receptor) => receptor.clave_receptor)
          .join(", "),
        subsidiary_name: payload.data.subsidiary.name,
        department_name: payload.data.department.name,
      };

      const index = state.portales.findIndex(
        (portal) => portal.id === updatedPortal.id
      );
      state.portales[index] = updatedPortal;
    },
    [updatePortales.pending]: (state) => {
      state.isFetchingUpdate = true;
    },
    [updatePortales.rejected]: (state, { payload }) => {
      state.isFetchingUpdate = false;
      state.isErrorUpdate = true;

      if (payload?.message) {
        state.errorMessage = payload?.message;
        if (payload?.error?.message) {
          state.errorMessage += ": " + JSON.stringify(payload?.error?.message);
        }
      } else {
        state.errorMessage =
          "Error de conexión, verifique su conexión a internet.";
      }
    },
    [deletePortales.fulfilled]: (state, { payload }) => {
      state.isFetchingDelete = false;
      state.isSuccessDelete = true;
      state.successMessage = payload.message;
      const newArray = state.portales.filter(
        (portal) => portal.id !== payload.portal_id
      );
      state.portales = newArray;
    },
    [deletePortales.pending]: (state) => {
      state.isFetchingDelete = true;
    },
    [deletePortales.rejected]: (state, { payload }) => {
      state.isFetchingDelete = false;
      state.isErrorDelete = true;
      if (payload?.message) {
        state.errorMessage = payload?.message;
        if (payload?.error?.message) {
          state.errorMessage += ": " + JSON.stringify(payload?.error?.message);
        }
      } else {
        state.errorMessage =
          "Error de conexión, verifique su conexión a internet.";
      }
    },
    [createPortales.fulfilled]: (state, { payload }) => {
      state.isFetchingCreate = false;
      state.isSuccessCreate = true;
      state.successMessage = payload.message;
      const newPortal = {
        ...payload.data,
        cuentas_receptores: payload.data.receptores
          .map((receptor) => receptor.clave_receptor)
          .join(", "),
        subsidiary_name: payload.data.subsidiary.name,
        department_name: payload.data.department.name,
      };
      state.portales = [...state.portales, newPortal];
    },
    [createPortales.pending]: (state) => {
      state.isFetchingCreate = true;
    },
    [createPortales.rejected]: (state, { payload }) => {
      state.isFetchingCreate = false;
      state.isErrorCreate = true;
      if (payload?.message) {
        state.errorMessage = payload?.message;
        if (payload?.error?.message) {
          state.errorMessage += ": " + JSON.stringify(payload?.error?.message);
        }
      } else {
        state.errorMessage =
          "Error de conexión, verifique su conexión a internet.";
      }
    },
    [getAutoCompleteReceptor.fulfilled]: (state, { payload }) => {
      state.isFetchingReceptor = false;
      state.isSuccessReceptor = true;
      state.isErrorReceptor = false;
      let newArray = [];
      payload.data.forEach((user) => {
        const newValue = {
          id: user.id,
          nombre: user.nombre,
        };
        newArray.push(newValue);
      });
      state.receptor_list = newArray;
    },
    [getAutoCompleteReceptor.pending]: (state) => {
      state.isFetchingReceptor = true;
    },
    [getAutoCompleteReceptor.rejected]: (state, { payload }) => {
      state.isFetchingReceptor = false;
      state.isSuccessReceptor = false;
      state.isErrorReceptor = true;
      state.errorMessage = payload?.message
        ? payload?.message
        : "Error en el servidor";
      state.receptor_list = [
        {
          id: "",
          nombre: "",
        },
      ];
    },
  },
});

export const {
  clearState,
  clearStateCreate,
  clearStateUpdate,
  clearStateDelete,
  clearStateReceptores,
  reset,
} = portalesSlice.actions;

export const selectPortales = (state) => state.portales;

export default portalesSlice.reducer;
