import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

function FormInputPassword({ name, control, label, req}) {
    return (
        <Controller
        name={name}
        control={control}
        rules={{
          required: req ? 'Este campo no puede quedar vacío': false,
        }}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
          formState
        }) => (
          <TextField
            autoComplete='new-password'
            helperText={error ? error.message : null}
            size="small"
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            type="password"
            label={label}
            variant="outlined"
          />
        )}
      />
    )
}

FormInputPassword.propTypes = {
    name: PropTypes.string,
    control: PropTypes.any,
    label: PropTypes.string,
}

export default FormInputPassword
