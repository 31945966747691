export const asignarSecuencia = (arreglo) => {
  // Recorremos el arreglo y asignamos la secuencia a cada elemento
  let num_facturas = arreglo.length + 1;
  const newArray = arreglo.map((factura) => {
    num_facturas--;
    return {
      // id: factura.id,
      ...factura,
      secuencia: num_facturas,
      // folio: factura.folio,
      // fecha: factura.fecha,
      // serie: factura.serie,
      // cliente: factura.receptor?.nombre ? factura.receptor.nombre : "n/a",
      // reenvios: factura.reenvios,
      // pedido: factura?.complementos[0]?.pedido_interno
      //   ? factura.complementos[0].pedido_interno
      //   : "Sin pedido",
      // embarque: factura?.complementos[0]?.num_embarque
      //   ? factura.complementos[0].num_embarque
      //   : "Sin embarque",
      // observaciones: factura?.complementos[0]?.observaciones
      //   ? factura.complementos[0].observaciones
      //   : "Sin observaciones",
      // vendedor: factura?.complementos[0]?.vendedor
      //   ? factura.complementos[0].vendedor
      //   : "Sin vendedor",
      // total: factura.total,
      // total_carga: factura?.complementos[0]?.total_carga
      //   ? factura.complementos[0].total_carga
      //   : 0.0,
      // productos: factura.detalles_cotizaciones,
    };
  });

  // Retornamos el arreglo con la secuencia asignada
  return newArray;
};
